import React, { useContext, useState, Fragment, useEffect,useMemo } from 'react';
import {Box, createStyles, FormControlLabel, Grid, Switch, Theme, Typography, Tooltip,
  InputAdornment, TextField} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import SimpleDialog from "../../components/SimpleDialog";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import UserContext from "../../contexts/UserContext";
import firebase from 'firebase';
import InfoIcon from '@material-ui/icons/Info';
import StarIcon from '@material-ui/icons/Star';
import globalState from "../../globalState";
import {useStoreState} from "pullstate";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SubtleInfoMessage from "../../components/SubtleInfoMessage";
import { Alert } from '@material-ui/lab';
import { useNavigate } from '@reach/router';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { ModeFormType } from '../../types/other';
import { configMainRoutesConsts } from '../../config/route';
import { useIntl } from 'react-intl';
import pageMessages from '../messages';
import globalMessages from '../../messages';
import contentMessages from './messages';
import useAdminTrainerCountsAndRoles from "../../hooks/useAdminTrainerCountsAndRoles";
import { getCurrentUserRole } from '../../api/usersApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
    },
    nonSticky: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      bottom: 0,
      zIndex: 1,
    },
    right: {
      position: 'absolute',
      right: 0,
    },
    icon: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(.5),
      marginBottom: theme.spacing(-0.3),
    },
    iconOrg: {
      marginRight: theme.spacing(.5),
      marginBottom: theme.spacing(-0.3),
    },
    infoIconStyle : {color: theme.palette.text.primary,  marginBottom: 30, cursor: 'pointer' },
    gridFlexColumn : {
      flexDirection: 'column'
    },
    mB20MT10 : {marginBottom: 20, marginTop: 10},
    lockItemStyle : {
      width: 50,
      height : 50,
      marginLeft : "10px",
      marginTop : "23px"
    },
    formControlLabel: {
      marginLeft: 5,
      flexGrow: 1,
      flexShrink: 0
    }
  }),
);

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#383838',
    color: '#e8e8e8',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);


// isSetMode is used for copying workout template
const ContentFormActionBar = ({
                                onSubmit,
                                onClose,
                                archived,
                                handleArchive,
                                disabled,
                                onSubmitLabel,
                                entityName,
                                mode,
                                isFormDirty,
                                isSetMode,
                                enableSave,
                                publicContent,
                                setPublicContent,
                                orgContent,
                                setOrgContent,
                                orgPublic,
                                setOrgPublic,
                                setDisableForm,
                                workoutAuthorId,
                                copiedContent,
                                setPublicTime,
                                premiumContent,
                                setPremiumContent,
                                origAuthor,
                                contentPrice,
                                setContentPrice,
                                isContentLocked,
                                setContentLocked
                              }: {
  onSubmit: () => void,
  onClose: () => void,
  archived: boolean | undefined,
  handleArchive: () => void,
  disabled: boolean,
  onSubmitLabel: string,
  entityName: 'Workout' | 'Program' | 'Meal' | 'NutritionPlan',
  mode: ModeFormType,
  isFormDirty: boolean,
  isSetMode: () => void,
  enableSave: boolean,
  publicContent?: boolean | undefined,
  setPublicContent?: (publicContent: boolean) => void,
  orgContent?: boolean | undefined,
  setOrgContent?: (orgContent: boolean) => void,
  orgPublic: boolean | undefined,
  setOrgPublic: (orgPublic: boolean) => void,
  setDisableForm?: (disabled:boolean) =>void,
  workoutAuthorId?: string,
  copiedContent?: boolean,
  setPublicTime?: any,
  premiumContent?: boolean | undefined,
  setPremiumContent?: (premiumContent: boolean) => void,
  origAuthor?: string;
  contentPrice?: number;
  setContentPrice?: (contentPrice?: number) => void;
  isContentLocked?: boolean;
  setContentLocked?: (isContentLocked: boolean) => void;
}) => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  const {formatMessage} = useIntl();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [copied, setCopied] = useState(false);
  const userContext = useContext(UserContext);

  const workoutAuthor = userContext.user && userContext.user.id === workoutAuthorId;
  const originalAuthor = userContext.user && userContext.user.id === origAuthor;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const currentOrganizationId = currentOrganization?.id || '';
  const {
    isMultipleTrainers,
    isAdmin,
    isSuperAdmin,
    isTrainer,
    isAssistantTrainer,
    isMFWOrganization
  } = useAdminTrainerCountsAndRoles(currentOrganizationId, userContext?.user);

  const isCopiedContent = copiedContent ? originalAuthor : copied ? workoutAuthor : true;
  const isWlNetActive = theme.theme.isWhiteLabel && userContext.user?.currentOrganization === theme.theme.orgId && theme.theme.whitelabel.isWlNetActive;
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;

      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const disabledContentChange = userContext?.user && getCurrentUserRole(userContext?.user) !== 'Admin' ||
  !currentOrganization?.stripeConnect?.stripe_user_id || currentOrganization?.stripeConnect?.restricted;

  const allowOnlyNumbersAndUpToTwoDecimalPlacesTextFieldOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>{
    const removeLeadingZeros = (num:number) => Number(num.toString());
    
    const inputValue = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(inputValue) ) {
      const vvv = removeLeadingZeros(Number(inputValue))
      e.target.value = String(vvv)
      setContentPrice && setContentPrice(vvv);
    }
  }

  const isEKeyDownPressed = (evt: React.KeyboardEvent<HTMLDivElement>) =>{
    return evt.key === 'e' && evt.preventDefault();
  }

  const handleLockOpenClick = () => {
    if (disabledContentChange) {
      return;
    } else {
      setContentLocked && setContentLocked(!isContentLocked);

      // Switch (mfwNet, premium, org, org to mfwNet) - off
      setPublicContent && setPublicContent(false);
      setPremiumContent && setPremiumContent(false);
      setOrgContent && setOrgContent(false);
      setOrgPublic && setOrgPublic(false);
    }
  }

  const isWhatYouNeed = orgPublic || orgContent || isContentLocked
  const isPublicOrLocked = publicContent || isContentLocked
  const combinedRenderCondition = useMemo(() => {
    const orgContentCondition =
      isMultipleTrainers && userContext.user && isCopiedContent &&
      ((!theme.theme.isWhiteLabel) || (isWlNetActive)) &&
      ((isAdmin) || (isTrainer && workoutAuthor) || (mode === "Create"));

    const postAsOrgCondition =
      isMultipleTrainers && userContext.user && isCopiedContent &&
      ((isAdmin) || (isTrainer && workoutAuthor) || (mode === "Create"));

    return { orgContentCondition, postAsOrgCondition };
  }, [isMultipleTrainers, userContext.user, isCopiedContent, theme, isWlNetActive, isAdmin, isTrainer, workoutAuthor, mode]);

  return (
    <Box className={`${isMobile ? "nonSticky" : "sticky"}`}>
      {currentOrganization?.stripeConnect && <Grid container className={classes.gridFlexColumn}>
        <Box>
          {currentOrganization && currentOrganization?.stripeConnect?.restricted?
            <Button
              onClick={()=>{
                navigate(configMainRoutesConsts.stripeConnect)
              }}
            >
              <Alert severity="warning">
              {formatMessage(contentMessages.there_is_a_problem_with_your_stripe_connected_account) } 
              </Alert>
            </Button>:
            <SubtleInfoMessage>
              {formatMessage(contentMessages.you_can_lock_your_content_set_price_and_sell_on_mfw_marketplace) } 
            </SubtleInfoMessage>
          }
          
        </Box>
        <Box className={classes.mB20MT10}>
          <TextField
            name='contentPrice'
            value={contentPrice}
            onChange={allowOnlyNumbersAndUpToTwoDecimalPlacesTextFieldOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color="textSecondary">€</Typography>
                </InputAdornment>
              ),
            }}
            label={formatMessage(contentMessages.content_price)}
            onKeyDown={isEKeyDownPressed}
            type='number'
            size="small"
            disabled={disabledContentChange}
          />
          {isContentLocked ?
            <LockIcon
              color={disabledContentChange ? "disabled" : "primary"}
              className={classes.lockItemStyle}
              onClick={() => {
                if (disabledContentChange) {
                  return;
                } else {
                  setContentLocked && setContentLocked(!isContentLocked);
                }
              }}
            />
            :
            <LockOpenIcon
              color={disabledContentChange ? "disabled" : "secondary"}
              className={classes.lockItemStyle}
              onClick={handleLockOpenClick}
            />
          }

          {/*prevod*/}
          {disabledContentChange &&
            <HtmlTooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                      {formatMessage(contentMessages.myContentOnMarketplace)}
                    <LockIcon className={classes.icon} fontSize="inherit" color="primary"/>
                  </Typography>
                  {formatMessage(contentMessages.onlyAdminsAllowed)}
                </Fragment>
              }
            >
              <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
            </HtmlTooltip>
          }
        </Box>
      </Grid>}

      <Grid container>
        {(isSuperAdmin || isMFWOrganization) &&
          <Box>
            <FormControlLabel
              style={{ marginBottom: 20, flexGrow: 1, flexShrink: 0, justifyContent: 'center' }}
              label={formatMessage(contentMessages.premium_content)}
              control={
                <Switch
                  checked={premiumContent}
                  onChange={() => {
                    setPremiumContent && setPremiumContent(!premiumContent);
                    setPublicTime(firebase.firestore.Timestamp.fromDate(new Date()));
                  }}
                  color="primary"
                  // @ts-ignore
                  disabled={isWhatYouNeed}
                />
              }
            />
          </Box>
        }
      </Grid>

      {disabled ?
      <Grid container spacing={2}>
        <Grid item>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <ArrowBackIosIcon/>
          </IconButton>
        </Grid>
        <Grid item>
            <Button
              color='primary'
              variant={'outlined'}
              onClick={()=>{
                isSetMode();
                setDisableForm && setDisableForm(!disabled);
                setCopied(true);
              }}
              >
                {formatMessage(pageMessages.copy_template)}
            </Button>
        </Grid>
      </Grid>
        :
        <Grid container spacing={2}>
          {userContext.user&& ((!isTrainer && !isAssistantTrainer)
            ||(isTrainer && userContext.user?.copyOk === true&&enableSave)
            || (mode === "Create")|| (workoutAuthor) || (mode === "Edit" && enableSave))&&
            <Grid item>
              <Button id = "mainButton"
                color='primary'
                variant={isFormDirty ? 'contained' : 'outlined'}
                onClick={onSubmit}
                disabled={disabled}
              >
                {enableSave ? formatMessage(pageMessages.save_new) : onSubmitLabel}
              </Button>
            </Grid>
          }
          {entityName && ((isTrainer && userContext.user?.copyOk === true) || !isTrainer && !isAssistantTrainer) &&
            <Grid item>
              <Button
                color='primary'
                variant={'outlined'}
                onClick={()=> {isSetMode(); setCopied(true)}}
              >
                {formatMessage(pageMessages.copy_template)}
              </Button>
            </Grid>
          }

          <Grid item>
            <Button color="secondary" onClick={onClose}>
              {formatMessage(globalMessages.cancel)}
            </Button>
          </Grid>

          <Grid item>
            <Grid container>
              {(!isAssistantTrainer && isCopiedContent) && ((!theme.theme.isWhiteLabel) || (isWlNetActive)) &&
                <Box>
                  <FormControlLabel
                    style={{marginLeft: 5, flexGrow: 1, flexShrink: 0, justifyContent: 'center'}}
                    label={formatMessage(pageMessages.post_to_public_feed,{netName: theme.theme.whitelabel.netName})}
                    control={
                      <Switch
                        checked={publicContent}
                        onChange={() => {
                          setPublicContent && setPublicContent(!publicContent);
                          setPublicTime(firebase.firestore.Timestamp.fromDate(new Date()));
                        }}
                        color="primary"
                        disabled={isWhatYouNeed}
                      />
                    }
                  />
                  <Tooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">{formatMessage(pageMessages.public_feed_title,{netName: theme.theme.whitelabel.netName})}</Typography>
                        {formatMessage(pageMessages.public_feed, {netName: theme.theme.whitelabel.netName, name: theme.theme.whitelabel.name})}
                      </Fragment>
                    }
                  >
                    <InfoIcon style={{ color: theme.mfwMuiWebTheme.palette.text.primary, marginLeft: -10, cursor: 'pointer' }} fontSize="small"/>
                  </Tooltip>
                </Box>
              }

              {combinedRenderCondition.orgContentCondition &&
                <Box>
                  <FormControlLabel    
                    label={formatMessage(pageMessages.org_content)}
                    className={classes.formControlLabel}
                    control={
                      <Switch
                        checked={orgContent}
                        onChange={() => {
                          setOrgContent && setOrgContent(!orgContent);
                        }}
                        color="primary"
                        disabled={isPublicOrLocked}
                      />
                    }
                  />
                  <Tooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">
                          {formatMessage(pageMessages.org_content_title)}
                          <Box>
                            <StarIcon className={classes.iconOrg} fontSize="inherit" color="secondary"/>
                            <Typography variant="caption" color="secondary">ORG Original</Typography>
                          </Box>
                        </Typography>
                        {formatMessage(pageMessages.org_content)}
                      </Fragment>
                    }
                  >
                    <InfoIcon style={{ color: theme.mfwMuiWebTheme.palette.text.primary, marginLeft: -10, cursor: 'pointer' }} fontSize="small"/>
                  </Tooltip>
                </Box>
              }

              {combinedRenderCondition.postAsOrgCondition &&
                <Box>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    label={
                      (isSuperAdmin || isMFWOrganization) ?
                        formatMessage(pageMessages.post_to_public_feed,{netName: theme.theme.whitelabel.netName}) :
                        formatMessage(pageMessages.org_public, {netName:theme.theme.whitelabel.netName}
                        )}
                    control={
                      <Switch
                        checked={orgPublic}
                        onChange={() => {
                          setOrgPublic && setOrgPublic(!orgPublic);
                          setPublicTime(firebase.firestore.Timestamp.fromDate(new Date()));
                        }}
                        color="primary"
                        disabled={isPublicOrLocked}
                      />
                    }
                  />
                  <Tooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">{formatMessage(pageMessages.org_public_feed_title, {netName: theme.theme.whitelabel.netName})}</Typography>
                        {formatMessage(pageMessages.org_public_feed, {netName: theme.theme.whitelabel.netName})}
                      </Fragment>
                    }
                  >
                    <InfoIcon style={{ color: theme.mfwMuiWebTheme.palette.text.primary, marginLeft: -10, cursor: 'pointer' }} fontSize="small"/>
                  </Tooltip>
                </Box>
              }
            </Grid>  
          </Grid>

          {
            mode === 'Edit' &&
            <Grid item>
              {
                archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled}
                        className={classes.right}>
                  {formatMessage(globalMessages.unarchive)}
                </Button>
              }

              {
                !archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled}
                        className={classes.right}>
                  {formatMessage(globalMessages.archive)}
                </Button>
              }
        
              { (entityName === 'Meal' || entityName === 'NutritionPlan') && 
                <SimpleDialog
                title={entityName === 'Meal' && archived ? formatMessage(contentMessages.unarchive_meal) :
                  entityName === 'Meal' && !archived ? formatMessage(contentMessages.archive_meal) :
                    entityName === 'NutritionPlan' && archived ? formatMessage(contentMessages.unarchive_plan) :
                    formatMessage(contentMessages.archive_plan)
                }
                text={entityName === 'Meal' && archived ? formatMessage(contentMessages.unarchive_meal_question) :
                      entityName === 'Meal' && !archived ? formatMessage(contentMessages.archive_meal_question) :
                      entityName === 'NutritionPlan' && archived ? formatMessage(contentMessages.unarchive_plan_question) :
                      formatMessage(contentMessages.archive_plan_question)
                }
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleArchive();
                }}
                onConfirmLabel={formatMessage(globalMessages.yes)}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={formatMessage(globalMessages.no)}
              />
              }
              { (entityName === 'Workout' || entityName === 'Program') && 
              <SimpleDialog
                title={formatMessage(entityName === 'Workout' && archived ? contentMessages.unarchive_workout :
                  entityName === 'Workout' && !archived ? contentMessages.archive_workout :
                    entityName === 'Program' && archived ? contentMessages.unarchive_program :
                    contentMessages.archive_program
                )}
                text={formatMessage(entityName === 'Workout' && archived ? contentMessages.unarchive_workout_question :
                  entityName === 'Workout' && !archived ? contentMessages.archive_workout_question :
                    entityName === 'Program' && archived ? contentMessages.unarchive_program_question :
                    contentMessages.archive_program_question
                )}
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleArchive();
                }}
                onConfirmLabel={formatMessage(globalMessages.yes)}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={formatMessage(globalMessages.no)}
              />
            }
            </Grid>
          }
        </Grid>
      }
    </Box>
  )
};

export default ContentFormActionBar;
