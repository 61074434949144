import React from "react";
import {createStyles, makeStyles, Theme, useMediaQuery} from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import MenuItem from "../../model/MenuItem";
import {auth} from "../../firebase";
import useNavigation from "../../hooks/useNavigation";
import Box from "@material-ui/core/Box";
import { setNotificationsToBeInactive } from "../../api/notificationApi";
import { useUserProvider } from "../../providers/UserProvider";
import app from "firebase";
import { SignOut as SignOutIcon } from "../../theme/MenuIcons";
import { useMfwThemeProvider } from "myfitworld-utils";
import { MenuType } from "../../types/other";
import navigationMessages from './messages';
import globalMessages from '../../messages';
import { useIntl } from "react-intl";
import { isMarketplacePage } from "../../hooks/marketplace/helpers";
import { configMainRoutesConsts } from "../../config/route";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer:{
      display:'flex',
      flexDirection:'column',
      flex:'1',
      borderRight:`0.2px solid ${theme.palette.text.secondary}`
    },
    logoLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      display: "flex",
      width: 80,
      padding: theme.spacing(1),
    },
    logoutBox: {
      bottom: "20px",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    listItem: {
      display: 'flex',
      flexDirection:'column',
      alignItems: 'center', 
      justifyContent:'center',
      minHeight: 64,
      maxHeight:70,
    },
    listItemIcon: {
      justifyContent: "center",
    },
    listItemLast: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: "flex-end",
    },
    listItemTextRoot: {
      //maxHeight: "1em",
    },
    listItemText: {
      fontSize: "0.8em !important",
      textAlign: "center",
    },
    listItemBox : {
      marginBottom: "0em"
    }
  })
);

const DrawerContent = (
  {
    menuItems,
    onClose,
    menuType
  } : 
  {
    menuItems: MenuItem[],
    onClose: () => void,
    menuType?: MenuType
  }) => {
  const classes = useStyles();
  const { navigation } = useNavigation();
  const { formatMessage } = useIntl();
  const {user} = useUserProvider();
  const theme = useMfwThemeProvider();
  const isMobile = useMediaQuery(theme.mfwMuiWebTheme.breakpoints.down('sm'));

  const isSelected = (item:MenuItem) => {
    let selected = window.location.pathname.indexOf(item.route) >= 0
    if(menuType === "clientMenu"){
      if(window.location.pathname.toString() === item.route.toString() && item.route.toString() === "/" ){
        selected = true;
      } else if(
        isMarketplacePage() && item.route.includes(configMainRoutesConsts.marketplaceWithoutLanguage)
      ){
        selected = true
      }
      else if(item.route.toString() === "/"){selected = false;}
    }
    return selected
  }

  const handleLogout = async () => {
    // check if messaging is supported at first by the browser
      try{
        if(app.messaging.isSupported()){
            const messaging = app.messaging();
            messaging.deleteToken().then();
            let token =  await messaging.getToken().then(token=>token).catch(e=>false)
            if(typeof token === "string"){
              setNotificationsToBeInactive(user?.id || "", token );
            }
        }
    }catch{}
    const language = user?.language;
    auth
      .signOut()
      .then((_) => {
        navigation(`/${language}`);
      })
      .catch((err) => null);
  };

  return (
    <Box className={classes.menuContainer}>
      <Box className={classes.logoLink} onClick={() => navigation('/')}>
        <img src={theme.theme.theme.logo} className={classes.logo} alt="Logo" />
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Box
            key={item.label}
            className={classes.listItemBox}
            onClick={() => {navigation(item.route); isMobile && onClose()}}
          >
            <ListItem button selected={isSelected(item)} className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText
                id={item.route}
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText,
                }}
                primary={formatMessage((navigationMessages as any)[item.label])}
              />
            </ListItem>
          </Box>
        ))}

      </List>
      <div className={classes.listItemLast}>
      {!user?<></>:
      <ListItem button className={classes.listItem} onClick={handleLogout}>
        <ListItemIcon className={classes.listItemIcon}>{<SignOutIcon/>}
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemText,
          }}
          primary={formatMessage(globalMessages.signout_button)}
        />
      </ListItem>
      }
      </div>
    </Box>
  );
};

export default DrawerContent;
