import React, { memo, useMemo } from "react";
import { Icon } from '../../components';
import { SignOut as SignOutIcon } from '../../icons'
import { useMfwThemeProvider } from "myfitworld-utils";


const SignOut = () => {
  const fillColor = useMfwThemeProvider().mfwMuiWebTheme.palette.text.secondary;

  const styes = useMemo(() => {
    return { stroke: 'none', fill: fillColor }
  }, [fillColor])

  return (
    <Icon icon={SignOutIcon} style={styes} />
  );
};

export default memo(SignOut);
