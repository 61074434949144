import { ProviderThemeType } from "myfitworld-utils/dist/types/ThemeTypes";
import React from "react";
import { Helmet } from "react-helmet";
import { config } from "../config";

const CustomHelmet = ({ theme }: { theme: ProviderThemeType }) => {
  return (
    <Helmet>
      <title>{theme.whitelabel.name}</title>
      <meta name="theme-color" content={theme.theme.backgroundColor.default} />
      {/* this should be changed: */}
      <meta name="description" content="Ultimate platform to manage personal trainings and keep you clients happy." />
      <meta name="msapplication-TileColor" content={theme.theme.backgroundColor.default} />
      <meta name="msapplication-TileImage" content={`${theme.whitelabel.s3ThemePath}/logo/icons/icon_144x144.png`}/>
      <meta name="theme-color" content={theme.theme.backgroundColor.default} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content={theme.theme.backgroundColor.default} />
      <meta name="apple-mobile-web-app-title" content={theme.whitelabel.name} />
      {/* MFW does not have SVG */}
      {theme.isWhiteLabel && <link rel="icon" href={`${theme.whitelabel.s3ThemePath}/logo/icons/favicon_192x192.png`} type="image/png" sizes="any" />}
      {theme.isWhiteLabel?
      <link rel="icon" href={`${theme.whitelabel.s3ThemePath}/logo/logo.svg`} type="image/svg+xml" sizes="any" />:
      <link rel="icon" type="image/x-icon" href="/mfw_favicon.ico" />}

      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <meta property="og:url"                content={theme.domain}/>
        <meta property="og:type"               content="website" />
        <meta property="og:title"              content={theme.whitelabel.name} />
        {/* this should be changed: */}
        <meta property="og:description"        content="Ultimate platform to manage personal trainings and keep you clients happy." />
        <meta property="og:image"              content={`${theme.whitelabel.s3ThemePath}/WEB/og_image.jpg`} />

        {
          config.htmlHeader.iconSizes
          .map(e=>
          {
            const href = `${theme.whitelabel.s3ThemePath}/logo/icons/icon_${e}.png`
            return <link
            rel="apple-touch-icon"
            sizes={e}
            href={href}
            key={e}
            ></link>
          }
          )
        }

      {
        config.htmlHeader.splashScreenSizes
          .map(e=>{
            const [w, h] = e.size.split('x');
            const href = `${theme.whitelabel.s3ThemePath}/WEB/splash-apple/apple-splash-${w}-${h}.jpg`
            return(
              <link
                rel="apple-touch-startup-image"
                sizes={e.size}
                href={href}
                key={href}
                media={e.media}
                ></link>
            )
          })
      }
      <link rel="manifest" href={`${theme.whitelabel.s3ThemePath}/WEB/manifest.json`} />
    </Helmet>
  );
};
export default CustomHelmet;
