import { Invitation, Role, User } from "myfitworld-model";
import { firestore } from "../../firebase";
import safeInvoke from "../../utils/safeInvoke";


export const fetchOrganizationInvitations = async ({
  userId,
  organizationId,
  roles,
  role,
  isWhiteLabel
}: {
  userId:string; 
  organizationId: string;
  roles: Role[];
  role: Role|undefined;
  isWhiteLabel: boolean
}) => {
  let invitations: Invitation[] = [];
  try {
    if(role){
        const invitationDocs = await getInvitationDocs(organizationId, userId, roles, role);
        invitationDocs.docs.map(doc => invitations.push({
            id: doc.id,
            ...doc.data(),
            createdAt: safeInvoke('toDate')(doc.data().createdAt)
            } as Invitation));
    }

    return invitations;
  } catch (error) {
    throw error;
  }
};

const getInvitationDocs = async (organizationId: string, userId: string, roles: Role[], role: Role) => {
    try{
        let collectionRef = firestore.collection('invitations')
        let invitationRef = (organizationId ?
            collectionRef.where('organization', '==', organizationId) :
            collectionRef
        ).where('role', 'in', roles).where('acceptedOn', '==', null);

        if (role === Role.Trainer || role === undefined) {
            invitationRef = invitationRef.where('invitedBy', '==', userId || '');
        }

        const invitationDocs = await invitationRef.orderBy('createdAt').get();

        return invitationDocs;
    } catch (error) {
        throw error;
    }
}