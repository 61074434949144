import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  error_delete_Organization_Client: {
    id: 'error.deleteOrganizationClient',
    defaultMessage: 'Error deleting organization client!'
  },
  error_loading_Organization_Clients: {
    id: 'error.loadingOrganizationClients',
    defaultMessage: 'Error loading organization clients!'
  },
  error_loading_Organization_Memebers: {
    id: 'error.loadingOrganizationMembers',
    defaultMessage: 'Error loading organization members!'
  },
  error_loading_Organization_TrainerList: {
    id: 'error.loadingOrganizationTrainerList',
    defaultMessage: 'Error loading organizations trainer list!'
  },
  error_updating_Organizations_In_User: {
    id: 'error.loadingOrganizationsInUser',
    defaultMessage: 'Error updating organizations in user!'
  },
})

export default messages;
