import React, {useEffect, useState, Fragment, useContext, useMemo, ChangeEvent} from "react";
import {Controller, useForm} from "react-hook-form";
import {Equipment, GeneralEntity, User, Role} from "myfitworld-model";
import {
  Accordion, Badge, Box, FormControlLabel, Grid, Switch, TextField, Typography,
  createStyles, Button, Tooltip, Theme, useMediaQuery, useTheme
} from "@material-ui/core";
import ContentFilterValues from "../../model/ContentFilter";
import initialContentFilterState from "../../utils/initialStates/initialContentFilterState";
import DifficultyLevel from "myfitworld-model/dist/enums/DifficultyLevel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ContentFilterOptions} from "../../utils/contentFilterOptions";
import Visible from "../../components/Visibile";
import useOrganization from "../../hooks/useOrganization";
import MuscleRegimeOptions from "../../utils/MuscleRegimeOptions";
import useGlobalCachedResourceList from "../../api/useGlobalCachedResourceList";
import MultiSelectField from "../../components/MultiSelectField";
import { useDispatch } from 'react-redux';
import { toggleShowPublic } from '../../redux/actions';
import InfoIcon from '@material-ui/icons/Info';
import StarIcon from '@material-ui/icons/Star';
import {makeStyles} from "@material-ui/core/styles";
import globalState from "../../globalState";
import {useStoreState} from "pullstate";
import {firestore} from "../../firebase";
import { getCurrentUserRole, isUserClient } from "../../api/usersApi";
import UserContext from "../../contexts/UserContext";
import { useMfwThemeProvider } from "myfitworld-utils";
import { HtmlTooltip } from "./ContentFormActionBar";
import { SortByOptions } from "./contentFilterConfig";
import { useIntl } from "react-intl";
import globalMessages from '../../messages';
import pageMessages from '../messages';
import contentMessages from './messages';

const _ = require("lodash");
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(-0.3),
    },
    iconOrg: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(-0.3),
    },
    lockSwitch: {
      "&$checked": {
        color: "yellow",
      },
    },
    FormControlLabelSw: { marginLeft: 4, flexGrow: 1, flexShrink: 0, fontWeight: 500 },
    gridCenter: { alignSelf: "center", paddingLeft: 8 },
    flexEndPaddingR8: { display: "flex", justifyContent: "flex-end", paddingRight: 8 },
    fGrow1MTop10: { flexGrow: 1, marginTop: 10 },
    controllerBox: { display: "flex", flexDirection: "row" },
    formControlLabelStyle: { marginLeft: 4, flexGrow: 1, flexShrink: 0, width: "100%" },
    infoIconStyle: { color: theme.palette.text.primary, marginLeft: -10, cursor: "pointer" },
  })
);

const difficultyLevels = [
  DifficultyLevel.BEGINNER,
  DifficultyLevel.MODERATE,
  DifficultyLevel.INTERMEDIATE,
  DifficultyLevel.ADVANCED,
  DifficultyLevel.OLYMPIC,
].map((level) => ({id: `${level}`, name: {en: `${level}`, sr: `${level}`, hr: `${level}`, de: `${level}`, es: `${level}`}}));

const countActiveFilters = (values: ContentFilterValues) => {
  return Object.keys(values).reduce((acc: number, key: string) => {
    if (values[key as keyof ContentFilterValues] &&
      key !== "showMFWOriginals" && key !== "showORGOriginals" && key !== "showPublic" &&
      key !== "showPremium" && key !== "isLockedContentDisplayed" ) {
      if(values[key as keyof ContentFilterValues] === "string" || (key === "marketplaceMinPrice" || key === "marketplaceMaxPrice" || key === "sortContent" )){
        acc += 1;
      } else {
        //@ts-ignore
        acc += values[key as keyof ContentFilterValues]?.length | 0;
      }
    }
    return acc;
  }, 0);
};

const ContentFilter = ({
                         onFilterChange,
                         disabled = false,
                         options,
                         query,
                         setQuery,
                         onQueryChange,
                         onAccordionToggle,
                         entityName
                       }: {
  onFilterChange: (filters: ContentFilterValues) => void;
  disabled?: boolean;
  options: ContentFilterOptions;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onQueryChange?: (value: string) => void;
  onAccordionToggle?: (value: boolean) => void;
  entityName?: string;

}) => {
  const {control, watch, getValues, setValue, reset, register} = useForm<ContentFilterValues>({
    defaultValues: {
      ...initialContentFilterState,
    }
  });

  const classes = useStyles();
  const mfwTheme = useMfwThemeProvider();
  const isWhiteLabel:boolean = mfwTheme.theme.isWhiteLabel;
  const showPublicWatcher = watch("showPublic");

  const {organizationId} = useOrganization();
  const userContext = useContext(UserContext);
  const userRole = getCurrentUserRole(userContext.user)

  // used for marketplace
  const isClient = useMemo(()=>isUserClient(userRole), [userRole])
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const preSetValues = () =>{
    const values = getValues();
    if (onFilterChange) onFilterChange(values);
  }

  const handleFilterChange = (onChange: (v: any) => void) =>  (value: any) => {
    onChange(value)
    preSetValues()
  }

  const resetFilters = () => {
    const values = getValues();
    if(onFilterChange) onFilterChange(values);
  }

  const {formatMessage} = useIntl();
  const {data: MuscleGroupsOptions} = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const {data: SportOptions} = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const {data: MotorSkillsOptions} = useGlobalCachedResourceList<GeneralEntity>('motorSkillsCache');
  const {data: HealthIssueOptions} = useGlobalCachedResourceList<GeneralEntity>('healthIssuesCache');
  const {data: EquipmentOptions} = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const {data: FitnessActivitiesOptions} = useGlobalCachedResourceList<GeneralEntity>('fitnessActivitiesCache');
  const {data: GoalsOptions} = useGlobalCachedResourceList<GeneralEntity>('goalsCache');
  const {data: GoodForOptions} = useGlobalCachedResourceList<GeneralEntity>('goodForCache');
  const {data: MealTypesOptions} = useGlobalCachedResourceList<GeneralEntity>('mealTypesCache');
  const {data: CounterProductiveForOptions} = useGlobalCachedResourceList<GeneralEntity>('counterProductiveForCache');
  const {data: TimePreparationOptions} = useGlobalCachedResourceList<GeneralEntity>('timePreparationCache');
  const {data: DietOptions} = useGlobalCachedResourceList<GeneralEntity>('dietCache');
  //const {data: TotalKcalOptions} = useGlobalCachedResourceList<GeneralEntity>('totalKcalCache');
  const {data: MicroNutrientOptions} = useGlobalCachedResourceList<GeneralEntity>('micronutrientCache');
  const {data: CategoryOptions} = useGlobalCachedResourceList<GeneralEntity>('categoryCache');
  const { data: GlycemicIndexRangeOptions } = useGlobalCachedResourceList<GeneralEntity>('glycemicIndexRangeCache');   
  const { data: GlycemicLoadRangeOptions } = useGlobalCachedResourceList<GeneralEntity>('glycemicLoadRangeCache');   
  const {data: DurationInWeeksOptions} = useGlobalCachedResourceList<GeneralEntity>('durationInWeeksCache');
  const {data: ExpertisesOptions} = useGlobalCachedResourceList<GeneralEntity>('expertisesCache');
  const {data: durationConfigurations} = useGlobalCachedResourceList<GeneralEntity>('durationConfigurationCache');
  

  const [expanded, setExpanded] = useState(false);
  const muscleRegimeOptions = MuscleRegimeOptions.map(options => ({
    id: options.value,
    name: {
      en: formatMessage((contentMessages as any)[options.label]),
      sr: formatMessage((contentMessages as any)[options.label]),
      de: formatMessage((contentMessages as any)[options.label]),
      es: formatMessage((contentMessages as any)[options.label]),
      hr: formatMessage((contentMessages as any)[options.label]),
    }
  }));

  const handleAccordionToggle = () => {
    onAccordionToggle && onAccordionToggle(!expanded);
    setExpanded(!expanded);
  };

  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const [numberTrainers, setNumberTrainers] = useState(-1);
  const [numberAdmins, setNumberAdmins] = useState(-1);

  const adminsTrainers = numberTrainers + numberAdmins;

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      firestore
        .collection("organizationUser")
        .where("orgId", "==", currentOrganization?.id || "")
        .where("role", "in", ["Trainer", "AssistantTrainer"])
        .where("archived","==", false)
        .get()
        .then((querySnapshot) => {
          const data: Array<User> = [];
          querySnapshot.forEach((snapshot) => {data.push({id: snapshot.id, ...snapshot.data()} as User)});
          setNumberTrainers(data.length);
          resolve(data);
        })
        .catch((err) => {
            reject({success: false});
          }
        )
    })
  }, [currentOrganization]);

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      firestore
        .collection("organizationUser")
        .where("orgId", "==", currentOrganization?.id || "")
        .where("role", "==", "Admin")
        .where("archived","==", false)
        .get()
        .then((querySnapshot) => {
          const data: Array<User> = [];
          querySnapshot.forEach((snapshot) => {data.push({id: snapshot.id, ...snapshot.data()} as User)});
          setNumberAdmins(data.length);
          resolve(data);
        })
        .catch((err) => {
            reject({success: false});
          }
        )
    })
  }, [currentOrganization]);

  const handleChangeNumeric = (e: ChangeEvent<HTMLInputElement>) => {
    const numCast = Number(e.target.value)
    e.target.value = String(numCast===0?"":numCast || "")
    preSetValues()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
   const { value } = e.target;
    if (onQueryChange) {
      onQueryChange(value || "");
    }
    else{
      setQuery(value);
    }
  }

  const handleShowPremiumSwitch = (onChange : (...event: any[]) => void) => (e : React.ChangeEvent<HTMLInputElement>) =>{
    onChange(e.target.checked);

    setValue("showMFWOriginals", false);
    setValue("showORGOriginals", false);
    setValue("showPublic", false);
    setValue("showPremium", false);
  }

  return (
    <Box mt={1} mb={1}>
      <Accordion onChange={handleAccordionToggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Badge badgeContent={countActiveFilters(getValues())} color="primary">
                <FilterListIcon color="primary"/>
              </Badge>
            </Box>
            <Box>
              <Typography variant="body1">{formatMessage(contentMessages.filters)}</Typography>
              <Typography variant="body2">{formatMessage(pageMessages.click_to)}{expanded ? formatMessage(pageMessages.collaps) : formatMessage(pageMessages.expand)}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} direction="row">
            <Visible if={options.sports}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"sports"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.sports)}
                      onChange={handleFilterChange(onChange)}
                      options={SportOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.muscleRegime}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"muscleRegime"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.muscle_regime_label)}
                      onChange={handleFilterChange(onChange)}
                      options={muscleRegimeOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.muscleGroups}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"muscleGroups"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.primary_muscle_groups)}
                      onChange={handleFilterChange(onChange)}
                      options={MuscleGroupsOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.secondaryMuscleGroups}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"secondaryMuscleGroups"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.secondary_muscle_groups)}
                      onChange={handleFilterChange(onChange)}
                      options={MuscleGroupsOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.fitnessActivity}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"fitnessActivity"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.activity)}
                      onChange={handleFilterChange(onChange)}
                      options={FitnessActivitiesOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.goals}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"goals"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.goals)}
                      onChange={handleFilterChange(onChange)}
                      options={GoalsOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.motorSkills}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"motorSkills"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.motor_skills)}
                      onChange={handleFilterChange(onChange)}
                      options={MotorSkillsOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.healthIssues}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"healthIssues"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.suitable_for_health_issues)}
                      onChange={handleFilterChange(onChange)}
                      options={HealthIssueOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.suitableForHealthIssues}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"suitableForHealthIssues"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.suitable_for_health_issues)}
                      onChange={handleFilterChange(onChange)}
                      options={HealthIssueOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.equipment}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"equipment"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.equipment)}
                      onChange={handleFilterChange(onChange)}
                      options={EquipmentOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.difficultyLevel}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"difficultyLevel"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.training_level)}
                      onChange={handleFilterChange(onChange)}
                      options={difficultyLevels}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            
            {/*Srediti i filterisanje content-a, jer ne radi kako treba kad je ovo ukljuceno*/}
            
            {/*<Visible if={options.durationConfiguration}>*/}
            {/*  <Grid item xs={12} md={4} lg={3}>*/}
            {/*    <Controller*/}
            {/*      name={"durationConfiguration"}*/}
            {/*      control={control}*/}
            {/*      render={({onChange, value}) => (*/}
            {/*        <MultiSelectField*/}
            {/*          label={translate("equipment_file.duration")}*/}
            {/*          onChange={handleFilterChange(onChange)}*/}
            {/*          options={durationConfigurations}*/}
            {/*          value={value}*/}
            {/*          disabled={disabled}*/}
            {/*          isMultiple={true}*/}
            {/*      />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Visible>*/}
            <Visible if={options.goodFor}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"goodFor"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.good_for)}
                      onChange={handleFilterChange(onChange)}
                      options={GoodForOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.counterProductiveFor}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"counterproductiveFor"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.counter_productive_for)}
                      onChange={handleFilterChange(onChange)}
                      options={CounterProductiveForOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.mealTypes}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"mealTypes"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.meal_types)}
                      onChange={handleFilterChange(onChange)}
                      options={MealTypesOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.timePreparation}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"timePreparation"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(contentMessages.time_preparation)}
                      onChange={handleFilterChange(onChange)}
                      options={TimePreparationOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            {/* <Grid item xs={12} md={4} lg={3}></Grid> */}
            <Visible if={options.diet}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"diet"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.diet)}
                      onChange={handleFilterChange(onChange)}
                      options={DietOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.micronutrient}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"micronutrient"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.micronutrients)}
                      onChange={handleFilterChange(onChange)}
                      options={MicroNutrientOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>

            <Visible if={options.category}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"category"}
                  control={control}
                  render={({onChange, value}) => (
                    <MultiSelectField
                      label={formatMessage(pageMessages.category)}
                      onChange={handleFilterChange(onChange)}
                      options={CategoryOptions}
                      value={value}
                      disabled={disabled}
                      isMultiple={true}
                  />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.glycemicIndexRange}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"glycemicIndexRange"}
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <MultiSelectField
                        label={formatMessage(pageMessages.glycemic_index_range)}
                        options={GlycemicIndexRangeOptions}
                        value={value}
                        onChange={handleFilterChange(onChange)}
                        disabled={disabled}
                        isMultiple={true}
                      />
                    );
                  }}
                />
              </Grid>
            </Visible>
            <Visible if={options.glycemicLoadRange}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"glycemicLoadRange"}
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <MultiSelectField
                        label={formatMessage(pageMessages.glycemic_load_range)}
                        options={GlycemicLoadRangeOptions}
                        value={value}
                        onChange={handleFilterChange(onChange)}
                        disabled={disabled}
                        isMultiple={true}
                      />
                    );
                  }}
                />
              </Grid>
            </Visible>
            <Visible if={options.durationInWeeks}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"durationInWeeks"}
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <MultiSelectField
                        label={formatMessage(contentMessages.duration_in_weeks)}
                        options={DurationInWeeksOptions}
                        value={value}
                        onChange={handleFilterChange(onChange)}
                        disabled={disabled}
                        isMultiple={true}
                      />
                    );
                  }}
                />
              </Grid>
            </Visible>
            <Visible if={options.expertise}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"expertise"}
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <MultiSelectField
                        label={"Expertises"}
                        options={ExpertisesOptions}
                        value={value}
                        onChange={handleFilterChange(onChange)}
                        disabled={disabled}
                        isMultiple={true}
                      />
                    );
                  }}
                />
              </Grid>
            </Visible>
            <Visible if={options.marketplaceProducts}>
              <Grid item xs={12} md={4} lg={3}>
              <TextField
              label={formatMessage(contentMessages.marketplaceMin)}
              placeholder={formatMessage(contentMessages.marketplaceMinPrice)}
              name='marketplaceMinPrice'
              fullWidth
              className={classes.fGrow1MTop10}
              inputRef={register({
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: "Please enter a valid number"
                }
              })}
              onChange={handleChangeNumeric}
            />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
              <TextField
              label={formatMessage(contentMessages.marketplaceMax)}
              placeholder={formatMessage(contentMessages.marketplaceMaxPrice)}
              name='marketplaceMaxPrice'
              fullWidth
              className={classes.fGrow1MTop10}
              onChange={handleChangeNumeric}
              inputRef={register({
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: "Please enter a valid number"
                }
              })}
            />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"sortContent"}
                  control={control}
                  render={({onChange, value}) => {
                    return (
                      <MultiSelectField
                        label={formatMessage(contentMessages.marketplaceSortByPrice)}
                        options={SortByOptions}
                        value={value?value:["asc"]}
                        onChange={handleFilterChange(onChange)}
                        disabled={disabled}
                        isMultiple={false}
                      />
                    );
                  }}
                />
              </Grid>
            </Visible>
              <Grid container>
                <Grid item xs={12} md={6} lg={3} className={classes.gridCenter}>
                  {options?.archived && <Controller
                    name={"archived"}
                    control={control}
                    render={({onChange, value}) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={value}
                            onChange={(e) => handleFilterChange(onChange)(e.target.checked)}
                            color="primary"
                            disabled={disabled}
                          />
                        }
                        label={formatMessage(pageMessages.archived)}
                      />
                    )}
                  />
                  }
                </Grid>
                <Grid item xs={12} md={6} lg={9} className={classes.flexEndPaddingR8}>
                  <Button
                    color='secondary'
                    variant='outlined'
                    disabled={disabled}
                    onClick={() => {reset(initialContentFilterState); resetFilters();}}
                  >
                    {formatMessage(contentMessages.reset_all_filters)}
                  </Button>
                </Grid>
              </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item md={3} xs={12}>
            <TextField
              label={formatMessage(contentMessages.searchfield_label)}
              placeholder={formatMessage(globalMessages.search_placeholder)}
              name='query'
              onChange={handleChange}
              fullWidth
              className={classes.fGrow1MTop10}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            {(
              (organizationId !== process.env.REACT_APP_MFW_ORGANIZATION_ID && !isWhiteLabel) ||
              (isWhiteLabel &&
                mfwTheme.theme.whitelabel.haveMFWContent
              )) &&
              userContext.user &&
              userRole !== Role.SuperAdmin &&
              !isClient && 
              options.showMFWOriginals &&
            <Controller
              name={"showMFWOriginals"}
              control={control}
              render={({onChange, value}) => (
                <Box className={classes.controllerBox}>
                  <Box>
                    <FormControlLabel
                      className={classes.formControlLabelStyle}
                      control={
                        <Switch
                          checked={value}
                          onChange={(e) => handleFilterChange(onChange)(e.target.checked)}
                          color="primary"
                          disabled={showPublicWatcher}
                        />
                      }
                      label={formatMessage(contentMessages.include_wl_content, {name: mfwTheme.theme.whitelabel.name})}
                    />
                  </Box>
                  <Box>
                    <Tooltip
                      title={
                        <Fragment>
                          <Typography color="inherit">
                            {formatMessage(contentMessages.show_wl_originals_filter_title, {name: mfwTheme.theme.whitelabel.name})}
                            <StarIcon className={classes.icon} fontSize="inherit" color="primary"/>
                            <Typography variant="caption" color="primary">{formatMessage(contentMessages.show_wl_originals_filter_title, {name: mfwTheme.theme.whitelabel.name})}</Typography>
                          </Typography>
                          {formatMessage(contentMessages.show_wl_originals_filter, {name: mfwTheme.theme.whitelabel.name})}
                        </Fragment>
                      }
                    >
                      <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            />
            }

            {
              adminsTrainers > 1 &&
              organizationId !== process.env.REACT_APP_MFW_ORGANIZATION_ID &&
              !isClient &&
              options.showORGOriginals &&
            <Controller
              name={"showORGOriginals"}
              control={control}
              render={({onChange, value}) => (
                <Box className={classes.controllerBox}>
                  <Box>
                    <FormControlLabel
                      className={classes.formControlLabelStyle}
                      control={
                        <Switch
                          checked={value}
                          onChange={(e) => handleFilterChange(onChange)(e.target.checked)}
                          color="primary"
                          disabled={showPublicWatcher}
                        />
                      }
                      label={formatMessage(contentMessages.show_org_originals)}
                    />
                  </Box>
                  <Box>
                    <Tooltip
                      title={
                        <Fragment>
                          <Typography color="inherit">
                            {formatMessage(contentMessages.show_org_originals_filter_title)}
                            <Box>
                              <StarIcon className={classes.iconOrg} fontSize="inherit" color="secondary"/>
                              <Typography variant="caption" color="secondary">{formatMessage(pageMessages.org_content_title)}</Typography>
                            </Box>
                          </Typography>
                          {formatMessage(contentMessages.show_org_originals_filter)}
                        </Fragment>
                      }
                    >
                      <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            />
            }
            {userContext.user &&
              (organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID ||
                (
                  currentOrganization &&
                  currentOrganization.isPremiumSwitchEnabled
                )
              ) && 
              !isClient  &&
              options.showPremium &&
            <Controller
              name={"showPremium"}
              control={control}
              render={({onChange, value}) => (
                <Box>
                  <FormControlLabel
                    className={classes.FormControlLabelSw}
                    control={
                      <Switch
                        checked={value}
                        onChange={(e) =>{
                          setValue("showMFWOriginals", false);
                          setValue("showORGOriginals", false);
                          handleFilterChange(onChange)(e.target.checked);

                          if (showPublicWatcher) {
                            handleFilterChange(onChange)(e.target.checked);
                            reset(initialContentFilterState);
                          }
                        }}
                        color="secondary"
                        disabled={disabled}
                      />
                    }
                    label={<b>Premium</b>}
                  />
                  <Tooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">{formatMessage(contentMessages.public_feed_filter_title,{netName: mfwTheme.theme.whitelabel.netName})}</Typography>
                        {formatMessage(contentMessages.public_feed_filter, {netName: mfwTheme.theme.whitelabel.netName, name: mfwTheme.theme.whitelabel.name})}
                      </Fragment>
                    }
                  >
                    <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
                  </Tooltip>
                </Box>
              )}
            />
            }
          </Grid>

          {!isMobile && <Grid item md={3} xs={12}/>}

          <Grid item md={3} xs={12}>
            {
            entityName !== 'Grocery' &&
            entityName !== 'Meal' &&
            entityName !== 'NutritionPlan' &&
            options?.showPublic !== false &&
            (
              !isWhiteLabel||
              (isWhiteLabel && organizationId === mfwTheme.theme.orgId)
            ) &&
            <Controller
              name={"showPublic"}
              control={control}
              render={({onChange, value}) => (
                <Box>
                  <FormControlLabel
                    className={classes.FormControlLabelSw}
                    control={
                      <Switch
                        checked={value}
                        onChange={(e) =>{
                          setValue("showMFWOriginals", false);
                          setValue("showORGOriginals", false);
                          handleFilterChange(onChange)(e.target.checked);
                          dispatch(toggleShowPublic());

                          if (showPublicWatcher) {
                            handleFilterChange(onChange)(e.target.checked);
                            reset(initialContentFilterState);
                          }
                        }}
                        color="secondary"
                        disabled={disabled}
                      />
                    }
                    label={<b>{formatMessage(contentMessages.public_feed_filter_title,{netName: mfwTheme.theme.whitelabel.netName})}</b>}
                  />
                  <Tooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">{formatMessage(contentMessages.public_feed_filter_title,{netName: mfwTheme.theme.whitelabel.netName})}</Typography>
                        {formatMessage(contentMessages.public_feed_filter, {netName: mfwTheme.theme.whitelabel.netName, name: mfwTheme.theme.whitelabel.name})}
                      </Fragment>
                    }
                  >
                    <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
                  </Tooltip>
                </Box>
              )}
            />
            }

            {options.isLockedContentDisplayed && <Controller
              name={"isLockedContentDisplayed"}
              control={control}
              render={({onChange, value}) => (
                <Box>
                  <FormControlLabel
                    className={classes.FormControlLabelSw}
                    control={
                      <Switch
                        checked={value}
                        onChange={handleShowPremiumSwitch(onChange)}
                        color="primary"
                        disabled={disabled}
                      />
                    }
                    label={<b>{formatMessage(contentMessages.locked_content)}</b>}
                  />
                  <HtmlTooltip
                    title={
                      <Fragment>
                        <Typography color="inherit">{formatMessage(contentMessages.locked_content)}</Typography>
                        {formatMessage(contentMessages.locked_content_info_message)}
                      </Fragment>
                    }
                  >
                    <InfoIcon className={classes.infoIconStyle} fontSize="small"/>
                  </HtmlTooltip>
                </Box>
              )}
            />}
          </Grid>

        </Grid>
      </Box>

    </Box>
  );
};

export default ContentFilter;
