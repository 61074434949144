import { useState, useEffect } from 'react';
import {getCurrentUserRole, getNumberOfAdminsTrainers} from "../api/usersApi";
import {Role, User} from "myfitworld-model";

const useAdminTrainerCountsAndRoles = (selectedOrganizationId: string, user: User | null) => {
  const [sumAdminsTrainers, setSumAdminsTrainers] = useState(0);
  const isMultipleTrainers = sumAdminsTrainers > 1;

  const isSuperAdmin = user && getCurrentUserRole(user) === Role.SuperAdmin;
  const isAdmin = user && getCurrentUserRole(user) === Role.Admin;
  const isAssistantTrainer = user && getCurrentUserRole(user) === Role.AssistantTrainer;
  const isTrainer = user && getCurrentUserRole(user) === Role.Trainer;
  const isMFWOrganization = user && user.currentOrganization === process.env.REACT_APP_MFW_ORGANIZATION_ID;

  useEffect(() => {
    const fetchUserData = async () => {
      const admins = await getNumberOfAdminsTrainers(selectedOrganizationId, Role.Admin);
      const assistantTrainers = await getNumberOfAdminsTrainers(selectedOrganizationId, Role.AssistantTrainer);
      const trainers = await getNumberOfAdminsTrainers(selectedOrganizationId, Role.Trainer);

      Promise.allSettled([admins, assistantTrainers, trainers])
        .then((results) => {
          const sumValues = results.reduce((acc, result) => {
            if (result.status === 'fulfilled') {
              return acc + result.value;
            } else {
              return acc;
            }
          }, 0);

          setSumAdminsTrainers(sumValues);
        })
    };

    fetchUserData();
  }, [selectedOrganizationId, setSumAdminsTrainers]);

  return {
    sumAdminsTrainers,
    isMultipleTrainers,
    isSuperAdmin,
    isAdmin,
    isTrainer,
    isAssistantTrainer,
    isMFWOrganization
  };
};

export default useAdminTrainerCountsAndRoles;
